"use client";

import { useContext, useReducer, createContext, useEffect } from "react";
import Cookies from "js-cookie";
import { logError } from "@lib/logger";
import { trackEvent } from "@lib/track-event";
import { useCustomerMetafields } from "@hooks/useCustomerMetafields";
import { useCurrencyState } from "@hooks/useCurrency";
import { getCart, setLineItems, cartApplyDiscount, addCartLine } from "@components/Cart/lib/queries";
import { addItem, updateItem } from "@components/Cart/lib";

const CartStateContext = createContext();
const CartDispatchContext = createContext();

export const ActionType = {
  OPEN_DRAWER: "@cart/OPEN_DRAWER",
  CLOSE_DRAWER: "@cart/CLOSE_DRAWER",
  SET_CART: "@cart/SET_CART",
  UPDATING_CART: "@cart/UPDATING_CART",
};

function cartReducer(state, action) {
  switch (action.type) {
    case ActionType.OPEN_DRAWER: {
      return { ...state, isOpen: true };
    }
    case ActionType.CLOSE_DRAWER: {
      return { ...state, isOpen: false };
    }
    case ActionType.SET_CART: {
      return { ...state, cart: action.cart };
    }
    case ActionType.UPDATING_CART: {
      return {
        ...state,
        cartIsLoading: action.loading,
        loading: action.loading,
        errorMsg: action.errorMsg || "",
      };
    }
    default: {
      state;
    }
  }
}

const initialState = {
  isOpen: false,
  cart: null,
  loading: false,
  cartIsLoading: false,
  errorMsg: "",
};

function CheckoutProvider({ children }) {
  const [state, dispatch] = useReducer(cartReducer, initialState);
  const {
    state: metafieldsState,
    addMetafield,
    updateMetafield,
    deleteMetafield,
  } = useCustomerMetafields();
  const { currency } = useCurrencyState();

  useEffect(() => {
    async function retrieveCart(cart) {
      try {
        const data = await getCart(cart);
        if (!data || data.completedAt) {
          Cookies.remove("kotn_cart");
          dispatch({ type: ActionType.SET_CART, cart: null });
          if (metafieldsState.metafield && metafieldsState.customer_id) {
            await deleteMetafield();
          }
          return;
        }

        dispatch({ 
          type: ActionType.SET_CART, 
          cart: {
            ...data,
            currencyCode: currency
          }
        });
        Cookies.set("kotn_cart", data.id);
        if (window.discountCode) {
          await cartApplyDiscount(window.discountCode, data.id);
          delete window.discountCode;
        }
      } catch (error) {
        logError(error);
        dispatch({ type: ActionType.UPDATING_CART, loading: false, errorMsg: error.message });
      }
    }

    let cartID = Cookies.get("kotn_cart");
    if (metafieldsState && metafieldsState.metafield && metafieldsState.metafield.value) {
      cartID = metafieldsState.metafield.value;
    }

    if (cartID) {
      retrieveCart(cartID);
    }
  }, [metafieldsState, currency]);

  useEffect(() => {
    async function changeCurrency(items) {
      try {
        dispatch({ type: ActionType.UPDATING_CART, loading: true });
        const updatedItems = items.map(({ item }) => {
          return {
            itemId: item.id,
            variantId: item.merchandise.id,
            quantity: item.quantity,
            customAttributes: item.customAttributes,
          };
        });
        const data = await setLineItems(null, updatedItems, currency);
        const errors = data.cartUserErrors ?? data.userErrors;
        if (errors.length) {
          throw errors[0];
        }
        if (data) {
          dispatch({ 
            type: ActionType.SET_CART, 
            cart: {
              ...data.cart,
              currencyCode: currency
            }
           });
          Cookies.set("kotn_cart", data.cart.id);
          await addMetafield(data.cart.id);
        }

        dispatch({ type: ActionType.UPDATING_CART, loading: false });
      } catch (error) {
        logError(error);
        dispatch({ type: ActionType.UPDATING_CART, loading: false, errorMsg: error.message });
      }
    }

    if (
      state.cart &&
      currency &&
      state.cart.currencyCode.toLowerCase() !== currency.toLowerCase()
    ) {
      changeCurrency(lineItems);
    }
  }, [currency]);

  const lineItems = state.cart?.lines ?? [];

  const openCart = () => {
    dispatch({ type: ActionType.OPEN_DRAWER });
    document.body.style.overflow = "hidden";
  };

  const closeCart = () => {
    dispatch({ type: ActionType.CLOSE_DRAWER });
    document.body.style.overflow = "unset";
  };

  const toggleCart = () => {
    if (state.isOpen) {
      closeCart();
    } else {
      openCart();
    }
  };

  const setItems = async (items) => {
    try {
      dispatch({ type: ActionType.UPDATING_CART, loading: true });
      const updatedItems = items.map((item) => {
        return {
          itemId: item.itemId,
          variantId: item.variantId,
          quantity: item.quantity,
          customAttributes: item.customAttributes,
        };
      });
      const data = await setLineItems(state.cart, updatedItems, currency);
      const errors = data.cartUserErrors ?? data.userErrors;

      if (errors.length) {
        throw errors[0];
      }


      dispatch({ type: ActionType.UPDATING_CART, loading: false });
      dispatch({ 
        type: ActionType.SET_CART, 
        cart: {
          ...data.cart,
          currencyCode: currency
        }
      });

      if (metafieldsState && metafieldsState.metafield) {
        if (metafieldsState.metafield.value !== data.cart.id) {
          updateMetafield(data.cart.id);
        }
      } else {
        addMetafield(data.cart.id);
      }

      Cookies.set("kotn_cart", data.cart.id);

      return data;
    } catch (error) {
      logError(error);
      dispatch({ type: ActionType.UPDATING_CART, loading: false, errorMsg: error.message });
      return false;
    }
  };


  const addCartItem = async (incomingItem, quantity = 1, productGroup) => {
    const currentItems = lineItems;
    const { updatedItems, newItem } = addItem(currentItems, incomingItem, quantity, productGroup && productGroup.specialNotes);
    
    if (newItem) {
      dispatch({ type: ActionType.UPDATING_CART, loading: true });
      const data = await addCartLine(state.cart, newItem);
      dispatch({ 
        type: ActionType.SET_CART, 
        cart: {
          ...data.cart,
          currencyCode: currency
        }
      });
      openCart();
      dispatch({ type: ActionType.UPDATING_CART, loading: false });
      return;
    }

    setItems(updatedItems).then((result) => {
      if (result) {
        //trackEvent("add_to_cart", { variant: item, cart: result.cart, productGroup });
        openCart();
      }
    });
  };

  const updateCartItem = (incomingItem) => {
    const currentItems = lineItems;
    const updatedItems = updateItem(currentItems, incomingItem);
    setItems(updatedItems);
  };

  return (
    <CartStateContext.Provider value={state}>
      <CartDispatchContext.Provider
        value={{ openCart, closeCart, toggleCart, updateCartItem, addCartItem }}
      >
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  );
}

function useCheckoutState() {
  const context = useContext(CartStateContext);
  if (context === undefined) {
    throw new Error("useCheckoutState must be used within a CheckoutProvider");
  }
  return context;
}

function useCheckoutDispatch() {
  const context = useContext(CartDispatchContext);
  if (context === undefined) {
    throw new Error("useCheckoutDispatch must be used within a CheckoutProvider");
  }
  return context;
}

export { CheckoutProvider, useCheckoutState, useCheckoutDispatch };
